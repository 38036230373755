import { render } from "./TrafficSourceFormModal.vue?vue&type=template&id=719dd8b6&scoped=true"
import script from "./TrafficSourceFormModal.vue?vue&type=script&lang=ts"
export * from "./TrafficSourceFormModal.vue?vue&type=script&lang=ts"

import "./TrafficSourceFormModal.vue?vue&type=style&index=0&id=719dd8b6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-719dd8b6"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QCardSection,QForm,QChip,QAvatar,QInput,QSpace});
