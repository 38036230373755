
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SendingTestModal from '@/components/common/ui/SendingTestModal.vue'
import { IStory } from '@/utils/types'

@Options({
  components: { SendingTestModal },
  emits: ['update:modelValue', 'update:detailCopy'],
})
export default class TestModeSelector extends Vue {
  @Prop()
  modelValue!: boolean

  @Prop()
  isHideCopy!: boolean

  @Prop()
  isDisable!: boolean

  @Prop()
  isShowTestModal!: boolean

  @Prop()
  story!: IStory

  sendingTestModalVisible = false

  get value() {
    return this.modelValue
  }

  set value(value: boolean) {
    this.$emit('update:modelValue', value)
  }

  get getToggleLabel() {
    return this.value ? this.$t('label.test') : this.$t('label.prod')
  }

  handleSendingTestModal() {
    this.sendingTestModalVisible = true
  }

  onCloseSendingTestModal() {
    this.sendingTestModalVisible = false
  }

  onDetailCopy() {
    this.$emit('update:detailCopy')
  }
}
