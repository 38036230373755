<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <img :src="qrCodeUrl" />
        <q-space class="q-my-md" />
        <div class="row no-wrap items-center">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps color="primary" icon="download" @click="onDownload"> </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ITrafficSource } from '@/utils/types'
import QRCode from 'qrcode'

@Options({
  components: { QRCode },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class QRCodeFormModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  data!: ITrafficSource

  qrCodeUrl = ''

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get modalTitle() {
    return this.$t('label.traffic_source.qr_code')
  }

  async created() {
    if (this.data.tracking_url) {
      this.qrCodeUrl = await QRCode.toDataURL(this.data.tracking_url, { errorCorrectionLevel: 'H' })
    }
  }

  // Function to convert base64 to Blob
  base64ToBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || ''
    const sliceSize = 1024
    const byteCharacters: string = atob(base64Data)
    const byteArrays: Uint8Array[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice: string = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers: number[] = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray: Uint8Array = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  async onDownload() {
    // Extract base64 data
    const base64Data: string = this.qrCodeUrl.split(',')[1]

    // Convert base64 to Blob
    const blob: Blob = this.base64ToBlob(base64Data, 'image/png') // Specify the MIME type here

    // Create a temporary URL to download the Blob
    const url: string = URL.createObjectURL(blob)

    // Create a temporary <a> element to trigger the download
    const a: HTMLAnchorElement = document.createElement('a')
    a.href = url
    a.download = `qr-code-${this.data.source_name}.jpg` // Set desired file name here
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  onCancel() {
    this.visible = false
  }
}
</script>
