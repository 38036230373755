<template>
  <div class="row no-wrap items-center date-select justify-between">
    <q-input
      outlined
      dense
      label="開始日"
      v-model="dateRange.from"
      max="2099-12-31"
      fill-mask
      input-class="smaller-input"
      readonly
    >
      <template v-slot:append>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date v-model="dateRange.from" mask="YYYY-MM-DD">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
        <q-icon name="close" @click.stop.prevent="dateRange.from = ''" class="cursor-pointer" v-if="dateRange.from" />
      </template>
    </q-input>
    <span class="text-subtitle2 text-grey-8 q-px-sm">-</span>
    <q-input
      outlined
      dense
      label="終了日"
      v-model="dateRange.to"
      max="2099-12-31"
      fill-mask
      input-class="smaller-input"
    >
      <template v-slot:append>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date v-model="dateRange.to" mask="YYYY-MM-DD">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
        <q-icon name="close" @click.stop.prevent="dateRange.to = ''" class="cursor-pointer" v-if="dateRange.to" />
      </template>
    </q-input>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { constant } from '@/utils/constants'
import { IDateRange } from '@/utils/types'
import { Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { useQuasar } from 'quasar'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onChangeDateRange'],
})
export default class DateRangeSelector extends mixins(AppMixin, BaseFormMixin) {
  @Prop()
  defaultDateRange!: IDateRange

  @Prop()
  disable!: boolean

  date_range = constant.DATE_RANGE
  maskDateRange = constant.DATE_FORMAT
  quasar = useQuasar()

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  @Watch('dateRange', { deep: true })
  dateRangeChanged() {
    if (this.dateRange == null) {
      this.dateRange = {
        from: '',
        to: '',
      }
    }

    const check = this.checkRange(this.dateRange.from, this.dateRange.to)
    if (check) {
      this.$emit('update:onChangeDateRange', this.dateRange)
    } else {
      this.dateRange = {
        from: '',
        to: '',
      }
      this.$emit('update:onChangeDateRange', this.dateRange)
    }
  }

  @Watch('defaultDateRange')
  getDefaultDateRange() {
    this.dateRange = this.defaultDateRange
  }

  checkRange(start, end) {
    const from = moment(start, 'YYYY-MM-DD')
    const to = moment(end, 'YYYY-MM-DD')
    const diff = to.diff(from, 'days')
    if (diff > this.date_range) {
      this.quasar.notify({
        type: 'negative',
        message: this.$t('errors.over_92_days'),
      })
      return false
    } else {
      return true
    }
  }

  mounted() {
    this.dateRange = this.defaultDateRange
  }
}
</script>

<style scoped>
</style>
