
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ObjectUtils } from '@/utils/objects'
import { IGoal, IInitialCampaign, IMessage, IPushCampaign, IValidRequest } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BaseFormDialogMixin extends Vue {
  @Prop()
  modalVisible!: boolean

  parentForm = {
    _id: '',
    app_id: '',
    display_id: '',
  }

  initForm = { _id: '', app_id: '' }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get isNew() {
    return !this.parentForm?._id
  }

  async checkSizeCardsOfScenarios(campaign: IInitialCampaign | IPushCampaign) {
    if (this.isNew) {
      return true
    }

    let totalMessages = 0
    if (this.parentForm.display_id.startsWith('M')) {
      const msg: IMessage = this.parentForm
      totalMessages = msg.messages?.length ?? 0
    } else if (this.parentForm.display_id.startsWith('G')) {
      const goal: IGoal = this.parentForm
      totalMessages = goal.details?.length ?? 0
      if (goal.is_continue_question) {
        totalMessages = totalMessages + 1
      }
    }

    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.parentForm.app_id ?? '',
      initial_id: '',
      push_id: '',
      card_id: this.parentForm._id ?? '',
      card_type: '',
      total_messages: totalMessages,
      campaign: campaign,
    }
    const resp = await dispatch(ACTION_APP.CHECK_LIMIT_CARDS, params)
    if (resp.count > 5) {
      const card = resp.card
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.size_must_be_between_1_and_5', {
          card_title: card.title,
          count: resp.count,
          campaign_type: resp.campaign_type,
          campaign_title: resp.campaign_title,
        }),
        persistent: true,
        html: true,
      })
      return false
    }
    return true
  }

  checkDraftValue() {
    if (Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)) {
      this.notifyUnSaveForm()
    } else {
      this.visible = false
    }
  }

  notifyUnSaveForm() {
    this.$q
      .dialog({
        title: this.$t('messages.confirm'),
        message: this.$t('messages.draft_form_unsaved'),
        cancel: {
          flat: true,
          label: this.$t('messages.no'),
        },
        ok: {
          flat: true,
          label: this.$t('messages.yes'),
        },
        persistent: true,
      })
      .onOk(async () => {
        this.visible = false
      })
  }

  onCancel() {
    this.checkDraftValue()
  }
}
