<template>
  <span id="tagContent" @click="copy" class="float-right cursor-pointer">
    {{ tagValue }}
    <q-icon name="content_copy" size="xs" />
    <q-tooltip> コピー </q-tooltip>
  </span>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class TagContent extends Vue {
  // Props
  @Prop()
  tagValue!: string

  tagCopySuccess(): void {
    this.$q.notify({
      message: this.$t('label.copied'),
      color: 'positive',
    })
  }

  copy(): void {
    const myFluffyTextarea = document.createElement('textarea')

    myFluffyTextarea.innerHTML = this.tagValue

    const parentElement = document.getElementById('tagContent')
    if (!parentElement) {
      return
    }

    parentElement.appendChild(myFluffyTextarea)
    myFluffyTextarea.select()

    document.execCommand('copy')

    parentElement.removeChild(myFluffyTextarea)
    this.tagCopySuccess()
  }
}
</script>
