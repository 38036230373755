<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    :title="$t('label.traffic_source.traffic_sources_list')"
    ref="welcomeList"
    v-if="trafficSources"
    :rows="trafficSources"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <!-- <q-th rowspan="2" key="action" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.prod') }}</span></q-th
        >
        <q-th rowspan="2" key="action" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.test') }}</span></q-th
        > -->
        <q-th rowspan="2" key="action" class="text-center" style="width: 100px"></q-th>
        <q-th :props="props" rowspan="2" key="source_name" style="width: 50px">
          <span class="text-bold">{{ $t('label.traffic_source.source_name') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="num_of_traffic" style="width: 50px">
          <span class="text-bold">{{ $t('label.traffic_source.num_of_traffic') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="num_of_users">
          <span class="text-bold">{{ $t('label.traffic_source.num_of_users') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="tracking_url">
          <span class="text-bold">{{ $t('label.traffic_source.tracking_url') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="qr_code" style="width: 100px">
          <span class="text-bold">{{ $t('label.traffic_source.qr_code') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <!-- <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td> -->
        <q-td key="action" :props="props.index">
          <q-btn size="sm" round outline icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="grey" icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td key="source_name" :props="props">
          {{ props.row.source_name }}
        </q-td>
        <q-td key="num_of_traffic" :props="props">
          {{ props.row.num_of_traffic }}
        </q-td>
        <q-td key="num_of_users" :props="props">
          {{ props.row.num_of_users }}
        </q-td>
        <q-td key="tracking_url" :props="props" class="tdbreak">
          <span class="hyper-link">
            <TagContent :tagValue="getTrackingURL(props.row)"></TagContent>
          </span>
        </q-td>
        <q-td key="qr_code" :props="props">
          <q-btn icon="qr_code_2" @click="onQRCode(props.row)" />
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top separator>
      <h2 class="text-h6 text-black">{{ $t('label.traffic_source.traffic_sources_list') }}</h2>
      <q-select
        outlined
        dense
        option-value="_id"
        option-label="campaign_name"
        v-model="selectedCampaignId"
        :options="campaigns"
        emit-value
        map-options
        class="q-ma-md"
        style="width: 240px"
        :label="$t('label.popup.campaign')"
      />
      <q-space />
      <DateRangeSelector
        :default-date-range="dateRange"
        :disable="false"
        @update:onChangeDateRange="changeDateRange"
      ></DateRangeSelector>
      <q-btn class="q-ml-md" no-caps color="primary" @click="onAdd(0)" :label="$t('add_new')" />
    </template>
  </q-table>

  <TrafficSourceFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedTrafficSource"
    @update:closeModal="onCloseModal"
  />

  <QRCodeFormModal
    v-if="modalQRCodeVisible"
    :modalVisible="modalQRCodeVisible"
    :data="selectedTrafficSource"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IApp, ICampaign, IDateRange, ITrafficSource } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_APP, ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TrafficSourceFormModal from '@/components/traffic-sources/TrafficSourceFormModal.vue'
import QRCodeFormModal from '@/components/traffic-sources/QRCodeFormModal.vue'
import TagContent from '@/components/common/ui/TagContent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { lastNMonth, now } from '@/utils/datetime'
import DateRangeSelector from '@/components/common/ui/DateRangeSelector.vue'

@Options({
  components: {
    CLoading,
    TrafficSourceFormModal,
    QRCodeFormModal,
    TagContent,
    DateRangeSelector,
  },
  directives: { maska },
  emits: ['update:changeTab'],
})
export default class TrafficSourceList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  modalQRCodeVisible = false
  selectedTrafficSource: ITrafficSource = {}
  selectingApp: IApp = {}
  selectedCampaignId = ''
  campaigns: ICampaign[] = []

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get trafficSources() {
    const result = cloneDeep(this.$store.getters.trafficSources) || []
    if (result.length > 0) {
      for (const element of result) {
        element.tracking_url = this.getTrackingURL(element)
      }
    }

    return result
  }

  get campaignId() {
    return this.$route.params.campaign_id || ''
  }

  @Watch('campaignId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }
    this.firstLoad = true

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.selectedAppId)

    const cams = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_COMPACT_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: true,
    })
    this.campaigns = cams
    this.selectedCampaignId = this.campaignId
    await this.loadTrafficSource()
    this.loading = false
    this.firstLoad = false
  }

  @Watch('selectedCampaignId')
  handleChangeCampaign() {
    if (this.firstLoad) {
      return
    }
    this.loadTrafficSource()
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value
    this.loadTrafficSource()
  }

  async loadTrafficSource() {
    await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      campaign_id: this.selectedCampaignId,
      date_from: this.dateRange.from,
      date_to: this.dateRange.to,
    })
  }

  async updateIsActive(item: ITrafficSource) {
    const isSuccess = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: ITrafficSource) {
    const isSuccess = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: ITrafficSource) {
    const menu = this.trafficSources.find((item) => item._id !== record._id && item.is_active === true)
    if (!menu) {
      if (!record.is_active) {
        this.updateIsActive(record)
      } else {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.updateIsActive(record)
          })
          .onCancel(async () => {
            record.is_active = false
          })
      }
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          menu.is_active = false
          this.updateIsActive(menu)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: ITrafficSource) {
    const target = this.trafficSources.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!target) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          target.is_test_mode = false
          this.updateTestMode(target)
        })

      record.is_test_mode = false
    }
  }

  onAdd() {
    this.selectedTrafficSource = {
      app_id: this.selectedAppId,
    }

    this.modalVisible = true
  }

  onCopy(data: ITrafficSource) {
    const targetCopy: ITrafficSource = cloneDeep(data)
    targetCopy._id = ''
    targetCopy.is_active = false
    targetCopy.is_test_mode = false
    targetCopy.source_name = targetCopy.source_name + ' Copy'
    this.selectedTrafficSource = targetCopy
    this.modalVisible = true
  }

  onEdit(data: ITrafficSource) {
    this.selectedTrafficSource = data
    this.modalVisible = true
  }

  async onDelete(data: ITrafficSource) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.modalQRCodeVisible = false
    this.selectedTrafficSource = {}
  }

  getTrackingURL(data: ITrafficSource) {
    const params = encodeURIComponent(
      `account_id=${this.selectingApp.account_id}&liff_id=${this.selectingApp.liff_id}&source_id=${
        data._id
      }&campaign_id=${data.campaign_id ?? ''}`
    )
    const url = `https://liff.line.me/${this.selectingApp.liff_id}?${params}`
    return url
  }

  onQRCode(data: ITrafficSource) {
    this.selectedTrafficSource = data
    this.modalQRCodeVisible = true
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>
<style lang="scss" scoped>
.tdbreak {
  word-break: break-all;
  white-space: normal;
}
</style>
