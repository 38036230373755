import { render } from "./TagContent.vue?vue&type=template&id=7c209260"
import script from "./TagContent.vue?vue&type=script&lang=ts"
export * from "./TagContent.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QTooltip});
