
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAppUser, ISendingTestRequest, IStory, IUser } from '@/utils/types'
import { constant } from '@/utils/constants'
import { ACTION_APP_USER, ACTION_STORY } from '@/store/actions'
import UserAvatar from '@/components/user/UserAvatar.vue'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { UserAvatar, CLoading },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class SendingTestModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  story!: IStory

  loading = false
  step = 1
  value = 0
  pushIndex = 0
  isTestMode = true
  selectedUsers: IAppUser[] = []

  lineUsers = []
  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  columns = [{ name: 'display_name', label: 'display_name', field: 'display_name', align: 'left' }]

  get pushName() {
    if (this.story.pushes) {
      return this.story.pushes[this.pushIndex].title
    }
    return ''
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeIsTestMode() {
    this.selectedUsers = []
  }

  selectPush(index: number) {
    this.value = 4
    this.pushIndex = index
  }

  onCancel() {
    this.visible = false
  }

  getUserInfo(display_name, picture_url) {
    const u: IUser = {
      photo_url: picture_url,
      display_name: display_name,
    }
    return u
  }

  async onRequest(props) {
    this.loading = true
    const { page, rowsPerPage } = props.pagination
    const returnedData = await this.fetchFromServer(page, rowsPerPage)
    if (returnedData) {
      // eslint-disable-next-line
      const items: [] = returnedData['items']
      this.lineUsers.splice(0, this.lineUsers.length, ...items)
      // eslint-disable-next-line
      this.pagination.rowsNumber = returnedData['total_items']
    }
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.loading = false
  }

  async fetchFromServer(page, rowsPerPage) {
    const items = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.story.app_id,
      is_test_mode: this.isTestMode,
      filter: {
        page: page,
        per_page: rowsPerPage,
      },
    })

    return items
  }

  onPrev() {
    this.step--
    this.lineUsers = []
    if (this.step === 3) {
      this.$nextTick(() => {
        // get initial data from server (1st page)
        this.$refs.tableRef.requestServerInteraction()
      })
    }
  }

  onNext() {
    if (this.step === 1) {
      if (this.value === 0) {
        this.$q.dialog({
          title: this.$t('error'),
          message: 'Please choose scenario!',
          persistent: true,
          html: true,
        })
        return
      }
    } else if (this.step === 3) {
      if (this.selectedUsers.length === 0) {
        this.$q.dialog({
          title: this.$t('error'),
          message: 'Please choose users!',
          persistent: true,
          html: true,
        })
        return
      }
    }
    this.lineUsers = []
    this.step++
    if (this.step === 3) {
      this.$nextTick(() => {
        // get initial data from server (1st page)
        this.$refs.tableRef.requestServerInteraction()
      })
    }
  }

  async onSend() {
    this.loading = true
    let initial_id = ''
    let push_id = ''
    const user_ids: string[] = []
    if (this.value === 3) {
      initial_id = this.story.initial?._id ?? ''
    } else {
      if (this.story.pushes) {
        push_id = this.story.pushes[this.pushIndex]._id ?? ''
      }
    }

    this.selectedUsers.forEach((element) => {
      user_ids.push(element.user_id ?? '')
    })

    const payload: ISendingTestRequest = {
      app_id: this.story.app_id,
      story_id: this.story._id,
      initial_id: initial_id,
      push_id: push_id,
      is_test_mode: this.isTestMode,
      user_ids: user_ids,
    }

    const { dispatch } = this.$store
    const result = await dispatch(ACTION_STORY.SENDING_TEST, payload)
    console.log(result, 'onSend')

    this.loading = false
    this.step++
  }
}
