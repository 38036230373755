
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IApp, ICampaign, IDateRange, ITrafficSource } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_APP, ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TrafficSourceFormModal from '@/components/traffic-sources/TrafficSourceFormModal.vue'
import QRCodeFormModal from '@/components/traffic-sources/QRCodeFormModal.vue'
import TagContent from '@/components/common/ui/TagContent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { lastNMonth, now } from '@/utils/datetime'
import DateRangeSelector from '@/components/common/ui/DateRangeSelector.vue'

@Options({
  components: {
    CLoading,
    TrafficSourceFormModal,
    QRCodeFormModal,
    TagContent,
    DateRangeSelector,
  },
  directives: { maska },
  emits: ['update:changeTab'],
})
export default class TrafficSourceList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  modalQRCodeVisible = false
  selectedTrafficSource: ITrafficSource = {}
  selectingApp: IApp = {}
  selectedCampaignId = ''
  campaigns: ICampaign[] = []

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get trafficSources() {
    const result = cloneDeep(this.$store.getters.trafficSources) || []
    if (result.length > 0) {
      for (const element of result) {
        element.tracking_url = this.getTrackingURL(element)
      }
    }

    return result
  }

  get campaignId() {
    return this.$route.params.campaign_id || ''
  }

  @Watch('campaignId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }
    this.firstLoad = true

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.selectedAppId)

    const cams = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_COMPACT_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: true,
    })
    this.campaigns = cams
    this.selectedCampaignId = this.campaignId
    await this.loadTrafficSource()
    this.loading = false
    this.firstLoad = false
  }

  @Watch('selectedCampaignId')
  handleChangeCampaign() {
    if (this.firstLoad) {
      return
    }
    this.loadTrafficSource()
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value
    this.loadTrafficSource()
  }

  async loadTrafficSource() {
    await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      campaign_id: this.selectedCampaignId,
      date_from: this.dateRange.from,
      date_to: this.dateRange.to,
    })
  }

  async updateIsActive(item: ITrafficSource) {
    const isSuccess = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: ITrafficSource) {
    const isSuccess = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: ITrafficSource) {
    const menu = this.trafficSources.find((item) => item._id !== record._id && item.is_active === true)
    if (!menu) {
      if (!record.is_active) {
        this.updateIsActive(record)
      } else {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.updateIsActive(record)
          })
          .onCancel(async () => {
            record.is_active = false
          })
      }
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          menu.is_active = false
          this.updateIsActive(menu)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: ITrafficSource) {
    const target = this.trafficSources.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!target) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          target.is_test_mode = false
          this.updateTestMode(target)
        })

      record.is_test_mode = false
    }
  }

  onAdd() {
    this.selectedTrafficSource = {
      app_id: this.selectedAppId,
    }

    this.modalVisible = true
  }

  onCopy(data: ITrafficSource) {
    const targetCopy: ITrafficSource = cloneDeep(data)
    targetCopy._id = ''
    targetCopy.is_active = false
    targetCopy.is_test_mode = false
    targetCopy.source_name = targetCopy.source_name + ' Copy'
    this.selectedTrafficSource = targetCopy
    this.modalVisible = true
  }

  onEdit(data: ITrafficSource) {
    this.selectedTrafficSource = data
    this.modalVisible = true
  }

  async onDelete(data: ITrafficSource) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.modalQRCodeVisible = false
    this.selectedTrafficSource = {}
  }

  getTrackingURL(data: ITrafficSource) {
    const params = encodeURIComponent(
      `account_id=${this.selectingApp.account_id}&liff_id=${this.selectingApp.liff_id}&source_id=${
        data._id
      }&campaign_id=${data.campaign_id ?? ''}`
    )
    const url = `https://liff.line.me/${this.selectingApp.liff_id}?${params}`
    return url
  }

  onQRCode(data: ITrafficSource) {
    this.selectedTrafficSource = data
    this.modalQRCodeVisible = true
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
