<template>
  <div class="row q-gutter-sm">
    <q-space />
    <q-btn color="primary" label="送信テスト" v-if="isShowTestModal" @click="handleSendingTestModal()"></q-btn>
    <q-btn-group>
      <q-btn
        size="sm"
        :class="value ? 'btn-save' : 'btn-save-grey'"
        :label="$t('label.test')"
        @click="value = true"
        :disable="isDisable"
      />
      <q-btn
        size="sm"
        :class="!value ? 'btn-save' : 'btn-save-grey'"
        :label="$t('label.prod')"
        @click="value = false"
        :disable="isDisable"
      />
    </q-btn-group>
    <q-btn
      size="sm"
      round
      outline
      color="grey"
      icon="content_copy"
      @click="onDetailCopy"
      class="q-mr-sm btn-custom"
      v-if="!isHideCopy"
    >
      <q-tooltip v-if="value"> {{ $t('label.when_test_mode') }} </q-tooltip>
      <q-tooltip v-if="!value"> {{ $t('label.when_prod_mode') }} </q-tooltip>
    </q-btn>
  </div>

  <SendingTestModal
    v-if="sendingTestModalVisible"
    :modalVisible="sendingTestModalVisible"
    :story="story"
    @update:closeModal="onCloseSendingTestModal"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SendingTestModal from '@/components/common/ui/SendingTestModal.vue'
import { IStory } from '@/utils/types'

@Options({
  components: { SendingTestModal },
  emits: ['update:modelValue', 'update:detailCopy'],
})
export default class TestModeSelector extends Vue {
  @Prop()
  modelValue!: boolean

  @Prop()
  isHideCopy!: boolean

  @Prop()
  isDisable!: boolean

  @Prop()
  isShowTestModal!: boolean

  @Prop()
  story!: IStory

  sendingTestModalVisible = false

  get value() {
    return this.modelValue
  }

  set value(value: boolean) {
    this.$emit('update:modelValue', value)
  }

  get getToggleLabel() {
    return this.value ? this.$t('label.test') : this.$t('label.prod')
  }

  handleSendingTestModal() {
    this.sendingTestModalVisible = true
  }

  onCloseSendingTestModal() {
    this.sendingTestModalVisible = false
  }

  onDetailCopy() {
    this.$emit('update:detailCopy')
  }
}
</script>
