
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { constant } from '@/utils/constants'
import { IDateRange } from '@/utils/types'
import { Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import useQuasar from 'quasar/src/composables/use-quasar.js';

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onChangeDateRange'],
})
export default class DateRangeSelector extends mixins(AppMixin, BaseFormMixin) {
  @Prop()
  defaultDateRange!: IDateRange

  @Prop()
  disable!: boolean

  date_range = constant.DATE_RANGE
  maskDateRange = constant.DATE_FORMAT
  quasar = useQuasar()

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  @Watch('dateRange', { deep: true })
  dateRangeChanged() {
    if (this.dateRange == null) {
      this.dateRange = {
        from: '',
        to: '',
      }
    }

    const check = this.checkRange(this.dateRange.from, this.dateRange.to)
    if (check) {
      this.$emit('update:onChangeDateRange', this.dateRange)
    } else {
      this.dateRange = {
        from: '',
        to: '',
      }
      this.$emit('update:onChangeDateRange', this.dateRange)
    }
  }

  @Watch('defaultDateRange')
  getDefaultDateRange() {
    this.dateRange = this.defaultDateRange
  }

  checkRange(start, end) {
    const from = moment(start, 'YYYY-MM-DD')
    const to = moment(end, 'YYYY-MM-DD')
    const diff = to.diff(from, 'days')
    if (diff > this.date_range) {
      this.quasar.notify({
        type: 'negative',
        message: this.$t('errors.over_92_days'),
      })
      return false
    } else {
      return true
    }
  }

  mounted() {
    this.dateRange = this.defaultDateRange
  }
}
